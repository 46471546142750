import { createAction } from "redux-actions";
import { RESET_INITIAL_STATE } from "./me";
import { upsertData } from "../utils/normalize";
import { createSelector } from "reselect";

export const projectTotalsQuery = `projectTotals (projectId: $projectId) { 
  batchId, batchName, languageCode, inProduction, inAmends, inNew1, inNewContent1Stage, inNew2, inNewContent2Stage, inNew3, inNewContent3Stage,  inAmended1,
  inAmendedContent1Stage, inAmended2, approved, inAmendedContent2Stage, inAmended3, inAmendedContent3Stage, total, percentageComplete, projectId
}`;

export const FETCH_PROJECT_TOTALS_SUCCESS = "FETCH_PROJECT_TOTALS_SUCCESS";

export const fetchProjectTotalsDetailsSuccess = createAction(
  FETCH_PROJECT_TOTALS_SUCCESS
);

export const projectTotalsActionHandlers = {
  [RESET_INITIAL_STATE]: () => projectTotalsInitialState,
  [FETCH_PROJECT_TOTALS_SUCCESS]: (state, { payload }) =>
    upsertData(
      state,
      payload,
      (entity) => `${entity.batchId}-${entity.languageCode}`
    ),
};

export const projectTotalsInitialState = { entities: {}, result: [] };

/**
 * Retrieve totals for all batches in a project
 *
 * @param   {object}    state
 * @param   {number}    projectId
 * @param   {string}    [languageCode]  if given, filters batches by the
 *  relevant language, otherwise combines all language totals for each batch
 * @param   {boolean}   [withClient]    if true, only show batches that have
 *  deliverables currently with the client
 *
 * @returns {Object[]}  batch totals
 */
export const projectTotals = createSelector(
  (state) => state.projectTotals.entities,
  (_, projectId) => Number(projectId),
  (_, _projectId, languageCode) => languageCode,
  (_, _projectId, _languageCode, withClient) => withClient,
  (projectTotals, projectId, languageCode, withClient) => {
    const projectBatches = Object.values(projectTotals).filter(
      (pt) => pt.projectId === projectId
    );

    const readyForReview = projectBatches.filter((pb) => {
      if (withClient) {
        return pb.inAmended1 + pb.inAmended2 + pb.inNew1 + pb.inNew2 > 0;
      } else {
        return pb;
      }
    });

    const withSelectedLanguage = readyForReview.filter((pt) => {
      if (languageCode) {
        return pt.languageCode === languageCode;
      } else {
        return pt;
      }
    });

    const totalFields = [
      "inProduction",
      "inAmends",
      "inAmended1",
      "inAmended2",
      "inAmended3",
      "inNew1",
      "inNew2",
      "inNew3",
      "approved",
      "total",
    ];

    return Object.values(
      withSelectedLanguage.reduce((acc, cur) => {
        const { batchId } = cur;
        const batch = acc[batchId];

        if (!batch) {
          // Copy first batch
          const { ...rest } = cur;
          acc[batchId] = rest;

          return acc;
        }

        // Add totals
        totalFields.reduce((acc, field) => {
          const value = cur[field];

          if (value !== null) batch[field] += value;
          return acc;
        }, {});

        batch.percentageComplete = Math.round(
          (batch.approved / batch.total) * 100
        );

        return acc;
      }, {})
    );
  }
);
