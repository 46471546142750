import React, { ReactNode, useEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.scss";
import classNames from "classnames";

interface ModalProps {
  body?: ReactNode;
  disableBackgroundDismiss?: boolean;
  display?: boolean;
  footer?: ReactNode;
  medium?: boolean;
  large?: boolean;
  title?: string;
  wide?: boolean;
  padding30?: boolean;
  type?: string;
  onClickCallback?: () => void;
  onClick?: (event: React.MouseEvent) => void | undefined;
  hideModal?: (event: React.MouseEvent) => void;
}

const Modal = (props: ModalProps) => {
  const modalRoot = document.getElementById("modal");
  const [container] = React.useState(() => {
    const el = document.createElement("div");
    return el;
  });

  useEffect(() => {
    modalRoot?.append(container);
    return () => {
      modalRoot?.removeChild(container);
    };
  }, [container, modalRoot, props.display, props.disableBackgroundDismiss]);

  if (!container || !props.display) {
    return null;
  }

  const modalStyles = classNames({
    [styles.modal]: true,
    [styles.displayModal]: props.display,
    [styles.padding30]: props.padding30,
  });

  const modalDialogStyles = classNames({
    [styles.modalDialog]: true,
    [styles.dialogMedium]: props.medium,
    [styles.dialogLarge]: props.large,
    [styles.dialogWide]: props.wide,
  });

  const hideOnClickHandler = (e) => {
    e.preventDefault();
    if (props.disableBackgroundDismiss) return null;
    if (props.onClickCallback) props.onClickCallback();
    if (props.hideModal) props.hideModal(e);
  };

  const preventClickBubbleUp = (e) => e.stopPropagation();

  return ReactDOM.createPortal(
    <div className={modalStyles} onClick={hideOnClickHandler}>
      <div className={styles.centerElement}>
        <div className={modalDialogStyles} onClick={preventClickBubbleUp}>
          <div className={styles.modalContent}>
            {props.title && (
              <div className={styles.modalHeader}>
                <h5 className={styles.modalTitle}>{props.title}</h5>
              </div>
            )}

            {props.body && <div className={styles.modalBody}>{props.body}</div>}
            {props.footer && (
              <div className={styles.modalFooter}>{props.footer}</div>
            )}
          </div>
        </div>
      </div>
    </div>,
    container
  );
};

export default Modal;
