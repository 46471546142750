import { safeObjectAccess } from "./objects";

/**
 * setLSItem - Set items in localStorage
 *
 * @param  {string} key
 * @param  {string} val
 * @param  {object} options = {}
 * @return {void}
 */
export const setLSItem = (key, val, options = {}) => {
  try {
    /* If an objectName is provided with objectVal and objectKey, then save the
     * key and value to an object in local storage, otherwise save it directly
     * to the local storage object */
    const { objectName, objectKey, objectVal } = options;
    const storageKey = objectName || key;
    const storageVal = objectVal
      ? JSON.stringify({
          ...JSON.parse(localStorage.getItem(objectName)),
          [objectKey]: objectVal,
        })
      : val;

    // if we passed a null value, then assume we want to remove it from local storage
    if (storageVal === null) {
      localStorage.removeItem(storageKey);
    } else {
      localStorage.setItem(storageKey, storageVal);
    }
  } catch (err) {
    return null;
  }
};

/**
 * getLSItem - Get items in localStorage
 *
 * @param  {string} key
 * @param  {object} options = {}
 * @return {object|string}
 */
export const getLSItem = (key, options = {}) => {
  try {
    const { objectKey } = options;
    return objectKey
      ? JSON.parse(localStorage.getItem(objectKey))
      : localStorage.getItem(key);
  } catch (err) {
    return null;
  }
};

/**
 * Set a value in local storage when given a list of keys to access through
 *
 * @param {*[]} keys an array of keys to set
 * @param {*} value the value to set in the store
 */
export const setLSItemV2 = ([baseKey, ...otherKeys], value) => {
  // if we're in a server render, just return
  if (typeof window === "undefined") return;

  const store = JSON.parse(window.localStorage.getItem(baseKey)) || {};

  // destructure for immutability
  const _store = { ...store };

  // store a reference to the current place in the store
  let _ref = _store;

  // navigate through the tree for each key
  otherKeys.forEach((key, idx) => {
    if (idx !== otherKeys.length - 1) {
      if (!_ref[key]) _ref[key] = {};
      _ref = _ref[key];
    } else {
      // if we are setting a null value, remove it
      if (value === null) {
        delete _ref[key];
      } else {
        _ref[key] = value;
      }
    }
  });

  window.localStorage.setItem(baseKey, JSON.stringify(_store));
};

/**
 * Remove a nested value in local storage
 *
 * @param {*[]} keys an array of keys to traverse the tree
 */
export const removeLSItemV2 = (keys) => setLSItemV2(keys, null);

/**
 * @param {String|String[]} keys one or more keys to access a local storage entry
 * @param {*} defaultValue
 */
export const getLSItemV2 = (keys, defaultValue) => {
  // if we're in a server render, just return
  if (typeof window === "undefined" || typeof keys === "undefined") return;

  // if keys is a string then return just the local storage item
  if (typeof keys === "string") {
    return window.localStorage.getItem(keys);
    // if keys is an array of length 1 treat it just like a string key
  } else if (Array.isArray(keys) && keys.length === 1) {
    return window.localStorage.getItem(keys[0]);
  }

  // extract the main item key and subsequent keys
  const [baseKey, ...subsequentKeys] = keys;

  // we know at this point the item in the store will be an object
  const store = JSON.parse(window.localStorage.getItem(baseKey)) || {};

  return safeObjectAccess(store, subsequentKeys, defaultValue);
};
