export const matchHTMLWords = (content, wordsToUnderline = [], styles) => {
  // If wordsToUnderline is empty, return the content as is
  if (wordsToUnderline.length === 0) {
    return content;
  }

  // Create a regex to match the words
  const regex = new RegExp(`\\b(${wordsToUnderline.join("|")})\\b`, "gi");

  const underlinedContent = content.replace(
    regex,
    (matched) => `<span class="${styles.underline}">${matched}</span>`
  );

  return underlinedContent;
};
