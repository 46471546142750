/**
 * Parses a url query string into an object of key-values
 *
 * @param {string} queryString the url query string
 * @param {string} [key] optional single value to extract from the query string
 * @param {string} [keyFn] a function to apply to the extracted value
 *
 * @returns {Object} the parsed query string
 * @author https://stackoverflow.com/a/13419367
 */
export default function parseQuery(queryString, key, keyFn) {
  if (!queryString) return {};

  const query = {};
  const pairs = (queryString[0] === "?"
    ? queryString.substr(1)
    : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }

  if (key) {
    // apply a function if needed
    if (keyFn) return query[key] ? keyFn(query[key]) : undefined;

    return query[key];
  }

  return query;
}

/**
 * Remove or add a prop into an object
 *
 * @param {Object} query the query object
 * @param {string} prop the prop to add or to remove based on the value
 * @param {string} value optional value
 *
 * @returns {Object} the object query with or without the prop
 */
export const handleQuery = (query, prop, value) => {
  if (value) {
    return { ...query, [prop]: value };
  }
  const { [prop]: omit, ...queryToSend } = query;
  return queryToSend;
};
