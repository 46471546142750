import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { isLoggedInUserAdmin } from "../../../utils/entitySelector";
import parseQuery from "../../../utils/parseQuery";
import Dropdown from "../../Dropdown/v4";
import FeatureToggle from "../../../components/FeatureToggle";
import ClientStage from "./ClientStage";
import styles from "./ProgressTab.module.scss";

export function DownloadColumn({ showFinal }) {
  const isAdmin = useSelector(isLoggedInUserAdmin);

  // always show downloads for approved, conditionally for with client stages
  if (showFinal) {
    return <span className={styles.download}>Downloads</span>;
  }

  if (!isAdmin) return null;

  return (
    <FeatureToggle toggle={"withClientDownloads"}>
      <span className={styles.download}>Downloads</span>
    </FeatureToggle>
  );
}

export const ProgressTab = ({
  batches,
  stageData,
  languageNames,
  showFinal,
  projectId,
  baseUrl,
  history,
  location,
}) => {
  const getBatchObject = (batchId) => {
    return batchId && batches.find((b) => b.batchId === batchId);
  };
  const getLanguageObject = (languageCode) => {
    return (
      languageCode && {
        languageCode,
        languageName: languageNames[languageCode],
      }
    );
  };

  const [selectedBatch, setSelectedBatch] = useState();
  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    const { batchId, languageCode } = parseQuery(location.search);

    filterBatch(Number(batchId), false);
    filterLanguage(languageCode, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  // use the selected batch to filter the original stage data
  const filteredStageData = useMemo(() => {
    return stageData.map((stage) => ({
      ...stage,
      batchData: [...stage.batchData]
        .filter((b) =>
          !selectedBatch ? true : b.batchId === selectedBatch.batchId
        )
        .filter((b) =>
          !selectedLanguage
            ? true
            : b.languageCode === selectedLanguage.languageCode
        ),
    }));
  }, [selectedBatch, selectedLanguage, stageData]);

  const dropdownBatches = useMemo(() => {
    const ids = stageData.reduce((acc, { batchData }) => {
      batchData.forEach(({ batchId, batchName }) => {
        acc[batchId] = batchName;
      });
      return acc;
    }, {});

    return ids;
  }, [stageData]);

  const updateQuerySearch = (term, value) => {
    const query = parseQuery(location.search);

    // if no value then remove from the search terms
    if (!value) {
      delete query[term];
    } else {
      query[term] = value;
    }

    // concat all query strings
    const search = Object.entries(query)
      .map(([k, v]) => `${k}=${v}`)
      .join("&");

    // only push a new history state if the params are different
    if (location.search !== `?${search}`) {
      history.push({ search });
    }
  };

  const filterBatch = (batchId, updateQueryString = true) => {
    if (updateQueryString) updateQuerySearch("batchId", batchId);
    setSelectedBatch(getBatchObject(batchId));
  };
  const filterLanguage = (languageCode, updateQueryString = true) => {
    if (updateQueryString) updateQuerySearch("languageCode", languageCode);
    setSelectedLanguage(getLanguageObject(languageCode));
  };

  return (
    <div>
      <div className={styles.headerControls}>
        <span className={styles.batch}>
          <Dropdown
            filterText={"filter by batch name"}
            onClick={filterBatch}
            options={dropdownBatches}
            title={selectedBatch ? selectedBatch.batchName : "Batches"}
          />
        </span>
        <span className={styles.counts}>Deliverables</span>

        {/* only show feedback due for in progress deliverables */}
        {!showFinal && <span className={styles.feedbackDue}>Feedback due</span>}
        <span className={styles.languageCode}>
          <Dropdown
            onClick={filterLanguage}
            options={languageNames}
            rightAligned
            small
            title={
              selectedLanguage ? selectedLanguage.languageName : "Language"
            }
          />
        </span>

        <DownloadColumn showFinal={showFinal} />
      </div>

      <div className={styles.flexContainer}>
        {filteredStageData.map(({ batchData, stageId, stageName }) => (
          <ClientStage
            key={stageId}
            baseUrl={baseUrl}
            batchData={batchData}
            projectId={projectId}
            showFinal={showFinal}
            stageId={stageId}
            stageName={stageName}
          />
        ))}
      </div>
    </div>
  );
};

ProgressTab.propTypes = {
  baseUrl: PropTypes.string,
  batches: PropTypes.array,
  history: PropTypes.object,
  languageNames: PropTypes.object,
  location: PropTypes.object,
  projectId: PropTypes.number,
  showFinal: PropTypes.bool,
  stageData: PropTypes.arrayOf(
    PropTypes.shape({
      batchData: PropTypes.arrayOf(
        PropTypes.shape({
          batchId: PropTypes.number.isRequired,
          batchName: PropTypes.string.isRequired,
          deliverableCount: PropTypes.number.isRequired,
          languageCode: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        })
      ).isRequired,
      stageId: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default ProgressTab;
