import React from "react";
import InputCell from "./InputCell";
import EditButtons from "./EditButtons";

export default class RowCell extends React.Component {
  render() {
    const {
      value,
      label,
      onChange,
      onToggleReadOnly,
      theme,
      row,
      cell,
      blockProps,
      block,
    } = this.props;
    return (
      <td
        data-value={value.trim()}
        data-label={label.trim()}
        className={theme.td}
        disable={true}
      >
        <InputCell
          theme={theme}
          onChange={onChange}
          value={value}
          textAreaStyle={theme.rowTextArea}
          onToggleReadOnly={onToggleReadOnly}
          row={row}
          cell={cell}
          blockProps={blockProps}
          block={block}
          render={({ showEditOptions }) =>
            !this.props.isWithClient &&
            this.props.hasEditOptions &&
            showEditOptions && (
              <EditButtons
                className={theme.rowButtons}
                onAddBefore={this.props.onRowAddBefore}
                onDelete={this.props.onRowDelete}
                onAddAfter={this.props.onRowAddAfter}
                onToggleReadOnly={onToggleReadOnly}
              />
            )
          }
        />
      </td>
    );
  }
}
