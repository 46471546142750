import React, { Component } from "react";
import styles from "./styles.module.scss";
import TableIcon from "./TableIcon";

export default class TableAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: 3,
      rows: 2,
      open: false,
    };
  }

  // When the popover is open and users click anywhere on the page,
  // the popover should close
  componentDidMount() {
    document.addEventListener("click", this.closePopover);
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.closePopover);
  }

  // Note: make sure whenever a click happens within the popover it is not closed
  onPopoverClick = () => {
    this.preventNextClose = true;
  };

  openPopover = () => {
    if (!this.state.open && !this.props.disabled) {
      this.preventNextClose = true;
      this.setState({
        open: true,
      });
    }
  };

  closePopover = () => {
    if (!this.preventNextClose && this.state.open) {
      this.setState({
        open: false,
      });
    }

    this.preventNextClose = false;
  };

  addTable = () => {
    const { editorState, onChange } = this.props;
    onChange(
      this.props.modifier(editorState, {
        columns: this.state.columns,
        rows: this.state.rows,
      })
    );
    this.closePopover();
  };

  changeCols = (evt) => {
    this.setState({ ...this.state, columns: evt.target.value });
  };
  changeRows = (evt) => {
    this.setState({ ...this.state, rows: evt.target.value });
  };

  render() {
    const popoverClassName = this.state.open
      ? styles.addVideoPopover
      : styles.addVideoClosedPopover;
    const buttonClassName = this.state.open
      ? styles.addVideoPressedButton
      : styles.addVideoButton;

    return (
      <div
        onMouseUp={this.openPopover}
        type="button"
        className={`${styles.buttonContainer}
           ${this.props.disabled ? styles.disabled : ""}`}
      >
        <span className={styles.buttonBase}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18px"
            viewBox="0 -960 960 960"
            width="18px"
            fill="#4e575e"
          >
            <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm240-240H200v160h240v-160Zm80 0v160h240v-160H520Zm-80-80v-160H200v160h240Zm80 0h240v-160H520v160ZM200-680h560v-80H200v80Z" />
          </svg>
        </span>
        {this.props.render && this.props.render({ onClick: this.openPopover })}
        <div className={popoverClassName} onClick={this.onPopoverClick}>
          <div>
            <label>Columns</label>
            <input
              type="text"
              placeholder="Columns"
              className={styles.addVideoInput}
              onChange={this.changeCols}
              value={this.state.columns}
            />
          </div>
          <div>
            <label>Rows</label>
            <br />
            <input
              type="text"
              placeholder="Rows"
              className={styles.addVideoInput}
              onChange={this.changeRows}
              value={this.state.rows}
            />
          </div>
          <button
            className={styles.addVideoConfirmButton}
            type="button"
            onClick={this.addTable}
          >
            Add
          </button>
        </div>
      </div>
    );
  }
}
