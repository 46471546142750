import { createSelector } from "reselect";
import orderBy from "lodash/orderBy";
import { nullObject } from "./nullObjects";
import { PersonType } from "../modules/people";
import { genericSelectorWithReference } from "./entitySelectorV2";
export { genericSelectorWithReference };

const Sort = Object.freeze({
  Asc: "Asc",
  Desc: "Desc",
});

const createDropDownItem = (entities, id, getLabel, isCurrency) => ({
  value: isNaN(id) ? id : Number(id),
  label: !isCurrency
    ? getLabel(entities[id])
    : getLabel(entities[id]).concat(" - " + id),
  object: entities[id],
});

/**
 * Create an array of dropdown values
 *
 * @param {string} entity key of the entity in state, (i.e accounts, parentAccounts, etc)
 * @param {string|Function} label field to display or a function which takes the
 *  current entity and returns a composite label
 * @param {string} referenceKey further filter the returned results with a given
 *  key from in the entity
 * @param {Object} [options]
 * @param {string} [options.sort] either Sort.Asc or Sort.Desc
 */
export const dropdownSelector = (
  entity,
  label,
  referenceKey,
  { sort } = {}
) => {
  return createSelector(
    (state, referenceId) => referenceId,
    (state, referenceId) => state[entity].entities,
    (state, referenceId) => state[entity].result,
    (referenceId, entities, result) => {
      // label is either a function or a key on the entity field
      const getLabel = typeof label === "function" ? label : (e) => e[label];

      // show all results if no reference key
      if (!referenceId) {
        return result.map((id) =>
          createDropDownItem(entities, id, getLabel, entity === "currencies")
        );
      }

      const arr = result.reduce((acc, id) => {
        return entities[id][referenceKey] === referenceId
          ? acc.concat(
              createDropDownItem(
                entities,
                id,
                getLabel,
                entity === "currencies"
              )
            )
          : acc;
      }, []);

      if (sort === Sort.Asc) return arr.sort((a, b) => a.value - b.value);
      if (sort === Sort.Desc) return arr.sort((a, b) => b.value - a.value);

      return arr;
    }
  );
};

/**
  @description creates a selector for an entity by name
  @param entity - key of the entity in state, (i.e accounts, parentAccounts, etc)
  @returns array of objects. Each object is the shape of the entity
*/
export const genericSelector = (entity, sort, fn) => {
  return createSelector(
    (state) => state[entity].entities,
    (state) => state[entity].result,
    (entities, result) => {
      const final = [];
      result.forEach((id) => {
        const entity = entities[id];
        const extendedEntity = fn ? fn(entity) : entity;
        final.push(extendedEntity);
      });
      if (sort) return orderBy(final, sort.keys, sort.dirs);
      return final;
    }
  );
};

/**
 * Individual selectors
 */
export const individualSelector = (entity) => {
  return createSelector(
    (state) => state[entity].entities,
    (_, id) => id,
    (entities, id) => entities[id] || nullObject
  );
};

export const accountById = individualSelector("accounts");
export const assignmentById = individualSelector("assignments");
export const batchById = individualSelector("batches");
export const deliverableById = individualSelector("deliverables");
export const orderFormById = individualSelector("orderForms");
export const parentAccountById = individualSelector("parentAccounts");
export const parentDeliverableById = individualSelector("parentDeliverables");
export const exportFormatById = createSelector(
  (state) => state.exportFormats,
  (_, projectId) => projectId,
  (exportFormats, projectId) => exportFormats[projectId] || {}
);

/**
 * @deprecated The method should not be used, use the "modules/projects" version instead
 */
export const projectById = individualSelector("projects");
/**
 * @deprecated The method should not be used, use the "modules/stages" version instead
 */
export const stageById = individualSelector("stages");

/**
 * @param {Object} state
 * @param {number} personId
 *
 * @returns {Object} person object
 */
export const personById = createSelector(
  (state) => state.people.entities,
  (_, personId) => Number(personId),
  (people, personId) => {
    const person = people[personId] || nullObject;

    return {
      ...person,
      fullName: `${person.firstName} ${person.lastName}`,
    };
  }
);

export const getLoggedInUser = (state) => personById(state, state.me);

export const getLoggedInUserType = createSelector(
  getLoggedInUser,
  (user) => user.personType
);

export const isLoggedInUserAdmin = (state) => {
  return getLoggedInUserType(state) === PersonType.Admin;
};

/**
  @description - below are a list of commonly used selectors
*/

// Common dropdown selectors
export const accountsDropdownSelector = dropdownSelector(
  "accounts",
  "accountName",
  "parentAccountId"
);
export const contentTypesDropdownSelector = dropdownSelector(
  "contentTypes",
  "contentTypeName"
);
export const languagesDropdownSelector = dropdownSelector(
  "languages",
  "languageName"
);
export const currenciesDropdownSelector = dropdownSelector(
  "currencies",
  "currencyName"
);
export const accountsDropDownSelector = dropdownSelector(
  "accounts",
  "accountName"
);
export const orderFormsDropdownSelector = dropdownSelector(
  "orderForms",
  ({ orderFormNumber, orderFormName }) =>
    `${orderFormNumber} - ${orderFormName}`
);
export const projectsDropdownSelector = dropdownSelector(
  "projects",
  "projectName"
);
export const briefingFieldsDropdownSelector = dropdownSelector(
  "briefingFields",
  "briefingFieldName"
);
export const rateBandsDropdownSelector = dropdownSelector(
  "rateBands",
  "rateBandName",
  "projectId"
);

/**
 * Workflows selector is only used when creating a new project or editing
 * So we want to store the workflowId but we want to show different fields
 * based on whether it is a creation/locaisation workflow
 * For this reason we need to send both an array of dropdowns and entities
 *
 * @param {Object} state
 *
 * @returns {Object} contains the dropdown array and entities for workflowType
 */
export const workflowsDropdownSelector = createSelector(
  dropdownSelector("workflows", "workflowName"),
  (state) => state.workflows.entities,
  (dropdowns, entities) => ({ dropdowns, entities })
);

/**
 * Creates dropdown-compatible list of stage names
 *
 * @param {Object} state
 * @param {number} [workflowId] optional workflowId
 *
 * @returns {Object[]} options, each containing a label (stage name) and value (stage name)
 */
export const stageNamesDropdownSelector = createSelector(
  (_, workflowId) => workflowId,
  (state) => state.stages.entities,
  (workflowId, entities) => {
    if (!workflowId) return [];

    // Get unique stage names within workflow
    const stages = {};
    Object.keys(entities).forEach((stageId) => {
      const stage = entities[stageId];
      if (stage.workflowId === workflowId) {
        stages[stage.stageName] = true;
      }
    });

    // Convert to dropdown options
    const options = [];
    Object.keys(stages).forEach((stageName) => {
      options.push({
        label: stageName,
        value: stageName,
      });
    });

    // Sort alphabetically and return
    return options.sort((a, b) => {
      if (a.label > b.label) return 1;
      if (a.label < b.label) return -1;
      return 0;
    });
  }
);

export const batchesDropdownSelector = dropdownSelector(
  "batches",
  "batchName",
  "projectId",
  { sort: Sort.Desc }
);
export const personDropdownSelector = dropdownSelector(
  "people",
  (entity) => `${entity.firstName} ${entity.lastName}`
);

// generic selectors
export const accountsSelector = genericSelector("accounts");
export const commentGroupsSelector = genericSelector("commentGroups");
export const deliverablesSelector = genericSelector("deliverables");
export const parentAccountsSelector = genericSelector("parentAccounts", {
  keys: [(p) => p.parentAccountName.toLowerCase()],
});
export const parentDeliverableBriefingFieldsSelector = genericSelector(
  "parentDeliverableBriefingFields"
);
export const peopleFullNameSelector = genericSelector("people", false, (p) => ({
  ...p,
  fullName: `${p.firstName} ${p.lastName}`,
}));
export const peopleSelector = genericSelector("people");
export const projectsSelector = genericSelector("projects");
export const searchSelector = createSelector(
  genericSelector("batchSearch"),
  (search) =>
    search.sort((a, b) => b.parentDeliverableId - a.parentDeliverableId)
);
export const transitionsSelector = genericSelector("transitions");

// TODO: https://quillcontent.atlassian.net/browse/QCC-1688 - Review creating payments rather than filtering here
export const paymentsSelector = createSelector(
  genericSelector("payments", { keys: ["createDate"], dirs: "desc" }),
  (payments) => payments.filter((p) => p.rate > 0)
);

export const parentAccountsWithoutHidden = createSelector(
  (state) => state.hidden.entities,
  (state) => state.favourites.entities,
  genericSelector("parentAccounts", {
    keys: [(p) => p.parentAccountName.toLowerCase()],
  }),
  (hidden, favourites, parentAccounts) => {
    const hiddenAccountIds = Object.values(hidden).map(
      ({ parentAccountId }) => parentAccountId
    );
    const favouriteParentAccounts = Object.values(favourites).map(
      ({ parentAccountId }) => parentAccountId
    );

    const test = parentAccounts.reduce((acc, { parentAccountId, ...rest }) => {
      if (!hiddenAccountIds.includes(parentAccountId)) {
        acc.push({
          parentAccountId,
          isFavourite: favouriteParentAccounts.includes(parentAccountId),
          ...rest,
        });
      }
      return acc;
    }, []);
    return test;
  }
);

export const favouritesSelector = createSelector(
  (state) => state.favourites.entities,
  (state) => state.accounts.entities,
  (state) => state.parentAccounts.entities,
  (state) => state.orderForms.entities,
  (state) => state.projects.entities,
  (hidden, accounts, parentAccounts, orderForms, projects) => {
    const {
      favouriteParentAccounts,
      favouriteAccounts,
      favouriteOrderForms,
      favouriteProjects,
    } = Object.values(hidden).reduce(
      (acc, { parentAccountId, accountId, orderFormId, projectId }) => {
        if (parentAccounts[parentAccountId]) {
          acc.favouriteParentAccounts[parentAccountId] =
            parentAccounts[parentAccountId];
        }

        Object.values(accounts).forEach((account) => {
          const currentAccountId = account.accountId;
          const currentParentAccountId = account.parentAccountId;

          if (currentAccountId === accountId) {
            acc.favouriteAccounts[currentAccountId] =
              accounts[currentAccountId];
            acc.favouriteParentAccounts[currentParentAccountId] =
              parentAccounts[currentParentAccountId];
          }

          if (currentParentAccountId === parentAccountId) {
            acc.favouriteAccounts[currentAccountId] =
              accounts[currentAccountId];
          }
        });

        Object.values(orderForms).forEach((orderForm) => {
          const currentOrderFormId = orderForm.orderFormId;
          const currentAccountId = orderForm.accountId;
          const currentParentAccountId =
            accounts[currentAccountId]?.parentAccountId;

          if (currentOrderFormId === orderFormId) {
            acc.favouriteOrderForms[currentOrderFormId] =
              orderForms[currentOrderFormId];
            acc.favouriteAccounts[currentAccountId] =
              accounts[currentAccountId];
            acc.favouriteParentAccounts[currentParentAccountId] =
              parentAccounts[currentParentAccountId];
          }

          if (currentAccountId === accountId) {
            acc.favouriteOrderForms[currentOrderFormId] =
              orderForms[currentOrderFormId];
          }

          if (currentParentAccountId === parentAccountId) {
            acc.favouriteOrderForms[currentOrderFormId] =
              orderForms[currentOrderFormId];
          }
        });

        Object.values(projects).forEach((project) => {
          const currentProjectId = project.projectId;
          const currentOrderFormId = projects[currentProjectId].orderFormId;
          const currentAccountId = orderForms[currentOrderFormId]?.accountId;
          const currentParentAccountId =
            accounts[currentAccountId]?.parentAccountId;

          if (currentProjectId === projectId) {
            acc.favouriteProjects[currentProjectId] =
              projects[currentProjectId];
            acc.favouriteOrderForms[currentOrderFormId] =
              orderForms[currentOrderFormId];
            acc.favouriteAccounts[currentAccountId] =
              accounts[currentAccountId];
            acc.favouriteParentAccounts[currentParentAccountId] =
              parentAccounts[currentParentAccountId];
          }

          if (currentOrderFormId === orderFormId) {
            acc.favouriteProjects[currentProjectId] =
              projects[currentProjectId];
          }

          if (currentAccountId === accountId) {
            acc.favouriteProjects[currentProjectId] =
              projects[currentProjectId];
          }

          if (currentParentAccountId === parentAccountId) {
            acc.favouriteProjects[currentProjectId] =
              projects[currentProjectId];
          }
        });

        return acc;
      },
      {
        favouriteParentAccounts: {},
        favouriteAccounts: {},
        favouriteOrderForms: {},
        favouriteProjects: {},
      }
    );
    return {
      favouriteParentAccounts,
      favouriteAccounts,
      favouriteOrderForms,
      favouriteProjects,
    };
  }
);

export const favouriteParentAccountSelector = createSelector(
  favouritesSelector,
  (favouritesSelector) => {
    const { favouriteParentAccounts } = favouritesSelector;
    return Object.values(favouriteParentAccounts).sort(
      (a, b) => b.parentAccountName - a.parentAccountName
    );
  }
);

export const favouriteAccountSelector = createSelector(
  favouritesSelector,
  (favouritesSelector) => {
    const { favouriteAccounts } = favouritesSelector;
    const accountTree = {};
    Object.values(favouriteAccounts).forEach((account) => {
      const { parentAccountId } = account;
      if (!accountTree[parentAccountId]) {
        accountTree[parentAccountId] = [];
      }
      accountTree[parentAccountId].push(account);
    });
    return accountTree;
  }
);

export const favouriteOrderFormSelector = createSelector(
  favouritesSelector,
  (favouritesSelector) => {
    const { favouriteOrderForms } = favouritesSelector;
    const orderFormTree = {};
    Object.values(favouriteOrderForms).forEach((orderForm) => {
      const { accountId } = orderForm;
      if (!orderFormTree[accountId]) {
        orderFormTree[accountId] = [];
      }
      orderFormTree[accountId].push(orderForm);
    });
    return orderFormTree;
  }
);

export const favouriteProjectSelector = createSelector(
  favouritesSelector,
  (favouritesSelector) => {
    const { favouriteProjects } = favouritesSelector;
    const projectTree = {};
    Object.values(favouriteProjects).forEach((project) => {
      const { orderFormId } = project;
      if (!projectTree[orderFormId]) {
        projectTree[orderFormId] = [];
      }
      projectTree[orderFormId].push(project);
    });
    return projectTree;
  }
);

export const hiddenSelector = createSelector(
  (state) => state.hidden.entities,
  (state) => state.accounts.entities,
  (state) => state.parentAccounts.entities,
  (state) => state.orderForms.entities,
  (state) => state.projects.entities,
  (hidden, accounts, parentAccounts, orderForms, projects) => {
    const {
      hiddenParentAccounts,
      hiddenAccounts,
      hiddenOrderForms,
      hiddenProjects,
    } = Object.values(hidden).reduce(
      (acc, { parentAccountId, accountId, orderFormId, projectId }) => {
        if (parentAccounts[parentAccountId]) {
          acc.hiddenParentAccounts[parentAccountId] =
            parentAccounts[parentAccountId];
        }

        Object.values(accounts).forEach((account) => {
          const currentAccountId = account.accountId;
          const currentParentAccountId = account.parentAccountId;

          if (currentAccountId === accountId) {
            acc.hiddenAccounts[currentAccountId] = accounts[currentAccountId];
            acc.hiddenParentAccounts[currentParentAccountId] =
              parentAccounts[currentParentAccountId];
          }

          if (currentParentAccountId === parentAccountId) {
            acc.hiddenAccounts[currentAccountId] = accounts[currentAccountId];
          }
        });

        Object.values(orderForms).forEach((orderForm) => {
          const currentOrderFormId = orderForm.orderFormId;
          const currentAccountId = orderForm.accountId;
          const currentParentAccountId =
            accounts[currentAccountId]?.parentAccountId;

          if (currentOrderFormId === orderFormId) {
            acc.hiddenOrderForms[currentOrderFormId] =
              orderForms[currentOrderFormId];
            acc.hiddenAccounts[currentAccountId] = accounts[currentAccountId];
            acc.hiddenParentAccounts[currentParentAccountId] =
              parentAccounts[currentParentAccountId];
          }

          if (currentAccountId === accountId) {
            acc.hiddenOrderForms[currentOrderFormId] =
              orderForms[currentOrderFormId];
          }

          if (currentParentAccountId === parentAccountId) {
            acc.hiddenOrderForms[currentOrderFormId] =
              orderForms[currentOrderFormId];
          }
        });

        Object.values(projects).forEach((project) => {
          const currentProjectId = project.projectId;
          const currentOrderFormId = projects[currentProjectId].orderFormId;
          const currentAccountId = orderForms[currentOrderFormId]?.accountId;
          const currentParentAccountId =
            accounts[currentAccountId]?.parentAccountId;

          if (currentProjectId === projectId) {
            acc.hiddenProjects[currentProjectId] = projects[currentProjectId];
            acc.hiddenOrderForms[currentOrderFormId] =
              orderForms[currentOrderFormId];
            acc.hiddenAccounts[currentAccountId] = accounts[currentAccountId];
            acc.hiddenParentAccounts[currentParentAccountId] =
              parentAccounts[currentParentAccountId];
          }

          if (currentOrderFormId === orderFormId) {
            acc.hiddenProjects[currentProjectId] = projects[currentProjectId];
          }

          if (currentAccountId === accountId) {
            acc.hiddenProjects[currentProjectId] = projects[currentProjectId];
          }

          if (currentParentAccountId === parentAccountId) {
            acc.hiddenProjects[currentProjectId] = projects[currentProjectId];
          }
        });

        return acc;
      },
      {
        hiddenParentAccounts: {},
        hiddenAccounts: {},
        hiddenOrderForms: {},
        hiddenProjects: {},
      }
    );
    return {
      hiddenParentAccounts,
      hiddenAccounts,
      hiddenOrderForms,
      hiddenProjects,
    };
  }
);

export const hiddenParentAccountSelector = createSelector(
  hiddenSelector,
  (hiddenSelector) => {
    const { hiddenParentAccounts } = hiddenSelector;
    return Object.values(hiddenParentAccounts).sort(
      (a, b) => b.parentAccountName - a.parentAccountName
    );
  }
);

export const hiddenAccountSelector = createSelector(
  hiddenSelector,
  (hiddenSelector) => {
    const { hiddenAccounts } = hiddenSelector;
    const accountTree = {};
    Object.values(hiddenAccounts).forEach((account) => {
      const { parentAccountId } = account;
      if (!accountTree[parentAccountId]) {
        accountTree[parentAccountId] = [];
      }
      accountTree[parentAccountId].push(account);
    });
    return accountTree;
  }
);

export const hiddenOrderFormSelector = createSelector(
  hiddenSelector,
  (hiddenSelector) => {
    const { hiddenOrderForms } = hiddenSelector;
    const orderFormTree = {};
    Object.values(hiddenOrderForms).forEach((orderForm) => {
      const { accountId } = orderForm;
      if (!orderFormTree[accountId]) {
        orderFormTree[accountId] = [];
      }
      orderFormTree[accountId].push(orderForm);
    });
    return orderFormTree;
  }
);

export const hiddenProjectSelector = createSelector(
  hiddenSelector,
  (hiddenSelector) => {
    const { hiddenProjects } = hiddenSelector;
    const projectTree = {};
    Object.values(hiddenProjects).forEach((project) => {
      const { orderFormId } = project;
      if (!projectTree[orderFormId]) {
        projectTree[orderFormId] = [];
      }
      projectTree[orderFormId].push(project);
    });
    return projectTree;
  }
);

export const hiddenIdSelector = createSelector(
  (state) => state.hidden.entities,
  (hidden) => {
    return Object.values(hidden).reduce(
      (acc, cur) => {
        if (cur.parentAccountId) {
          acc.parentAccountIds.push(cur.parentAccountId);
        }
        if (cur.accountId) {
          acc.accountIds.push(cur.accountId);
        }

        if (cur.orderFormId) {
          acc.orderFormIds.push(cur.orderFormId);
        }

        if (cur.projectId) {
          acc.projectIds.push(cur.projectId);
        }
        return acc;
      },
      { parentAccountIds: [], orderFormIds: [], accountIds: [], projectIds: [] }
    );
  }
);

export const favouriteIdSelector = createSelector(
  (state) => state.favourites.entities,
  (favourites) => {
    return Object.values(favourites).reduce(
      (acc, cur) => {
        if (cur.parentAccountId) {
          acc.parentAccountIds.push(cur.parentAccountId);
        }
        if (cur.accountId) {
          acc.accountIds.push(cur.accountId);
        }

        if (cur.orderFormId) {
          acc.orderFormIds.push(cur.orderFormId);
        }

        if (cur.projectId) {
          acc.projectIds.push(cur.projectId);
        }
        return acc;
      },
      { parentAccountIds: [], orderFormIds: [], accountIds: [], projectIds: [] }
    );
  }
);

/**
 * @param {Object} state
 * @returns {Object[]} array of unarchived payments
 */
export const unarchivedPaymentsSelector = createSelector(
  paymentsSelector,
  (payments) => payments.filter((p) => p.paymentStatus !== "Archived")
);

// generic selectors with references
export const assignmentsByGroupSelector = genericSelectorWithReference(
  "assignments",
  "assignmentGroupId",
  {
    filter: (assignments) => assignments.filter((a) => !a.reassigned),
  }
);
export const assignmentsByDeliverableId = genericSelectorWithReference(
  "assignments",
  "deliverableId"
);

export const orderFormProjectsSelector = genericSelectorWithReference(
  "projects",
  "orderFormId"
);
export const projectRatesSelector = genericSelectorWithReference(
  "rates",
  "projectId"
);
export const rateBandsByProjectIdSelector = genericSelectorWithReference(
  "rateBands",
  "projectId"
);
export const quotedRatesByProjectIdSelector = genericSelectorWithReference(
  "quotedRates",
  "projectId"
);
export const versionHistoryByDeliverableId = genericSelectorWithReference(
  "versionHistory",
  "deliverableId"
);
export const attachmentsByParentDeliverable = genericSelectorWithReference(
  "attachments",
  "parentDeliverableId"
);

export const batchesByProjectId = genericSelectorWithReference(
  "batches",
  "projectId",
  {
    filter: (batches) => batches.filter((b) => !b.archived),
    sortDesc: "batchId",
  }
);

/**
 * @param {Object}  state
 * @param {number}  deliverableId
 */
export const transitionHistoryByDeliverableId = createSelector(
  (state) => state.transitionHistory.entities,
  (_, deliverableId) => deliverableId,
  (transitionHistory, deliverableId) => {
    return Object.values(transitionHistory).filter(
      (th) => th.deliverableId === deliverableId
    );
  }
);

/**
 * Returns the assignments navigable within the current 'actionable' context,
 * i.e. if the assignment is actionable, an array of all actionable assignments
 * within the group is returned
 *
 * @param {Object} state
 * @param {Object} assignment the current assignment
 *
 * @returns {Object[]} array of assignments
 */
export const navigableAssignmentsSelector = createSelector(
  (state, { assignmentGroupId }) =>
    assignmentsByGroupSelector(state, assignmentGroupId),
  (state, assignment) => assignment,
  (state) => state.featureToggles,
  (assignmentsInGroup, currentAssignment, featureToggles) => {
    return featureToggles.QCC_1396_splitActionableAssignments
      ? assignmentsInGroup.filter(
          (a) => a.actionable === currentAssignment.actionable
        )
      : assignmentsInGroup;
  }
);

export const projectBannedWordsSelector = (state, projectId, languageCode) => {
  const bannedWords = genericSelectorWithReference("bannedWords", "projectId")(
    state,
    projectId
  );
  return languageCode
    ? bannedWords.filter(
        (bannedWord) => bannedWord.languageCode === languageCode
      )
    : bannedWords;
};

export const paymentSelector = genericSelectorWithReference(
  "payments",
  "paymentId"
);
export const workflowStagesSelector = genericSelectorWithReference(
  "stages",
  "workflowId",
  { sort: { keys: ["stagePosition"] } }
);
export const projectBriefingFieldsSelector = genericSelectorWithReference(
  "briefingFields",
  "projectId",
  { sort: { keys: ["briefingFieldPosition"] } }
);
export const projectSourceFieldsSelector = genericSelectorWithReference(
  "sourceFields",
  "projectId",
  { sort: { keys: ["taskFieldPosition"] } }
);
export const projectTaskFieldsSelector = genericSelectorWithReference(
  "taskFields",
  "projectId",
  { sort: { keys: ["taskFieldPosition"] } }
);
export const projectKeywordGroupsSelector = genericSelectorWithReference(
  "keywordGroups",
  "projectId",
  { sort: { keys: ["keywordGroupPosition"] } }
);
export const projectDefaultAssigneesSelector = genericSelectorWithReference(
  "defaultAssignees",
  "projectId"
);
export const batchParentDeliverableEntitiesSelector =
  genericSelectorWithReference("parentDeliverables", "batchId", {
    asEntity: true,
  });
export const parentDeliverableBriefingFieldsByParentDeliverableSelector =
  genericSelectorWithReference(
    "parentDeliverableBriefingFields",
    "parentDeliverableId"
  );
export const parentDeliverableSourceFieldsByParentDeliverableSelector =
  genericSelectorWithReference(
    "parentDeliverableSourceFields",
    "parentDeliverableId"
  );
export const parentDeliverableKeywordsSelector = genericSelectorWithReference(
  "keywords",
  "parentDeliverableId"
);
export const workflowTransitionsSelector = genericSelectorWithReference(
  "transitions",
  "workflowId"
);
export const deliverableContentEntitiesSelector = genericSelectorWithReference(
  "taskFieldContent",
  "deliverableId",
  { asEntity: true }
);
export const transitionLogSelector = genericSelectorWithReference(
  "transitionLogs",
  "deliverableId",
  { sort: { keys: ["createDate"], dirs: ["desc"] } }
);

export const workflowStagesSelectorByTypes = createSelector(
  workflowStagesSelector,
  (_state, _workflowId, stageTypes) => stageTypes,
  (stages, stageTypes) => {
    return stages.filter(({ stageType }) => stageTypes.includes(stageType));
  }
);
export const chargeableProjectStagesSelector = createSelector(
  workflowStagesSelector,
  (stages) => stages.filter((s) => s.chargeable)
);

// other selectors
export const projectLanguagesSelector = createSelector(
  (state, projectId) => state.projectLanguages,
  (state, projectId) => Number(projectId),
  (projectLanguages, projectId) => {
    return projectLanguages.filter((pl) => pl.projectId === projectId);
  }
);

export const projectTeamsSelector = createSelector(
  (state, projectId) => state.projectTeams,
  (state, projectId) => Number(projectId),
  (projectTeams, projectId) => {
    return projectTeams.filter((pl) => pl.projectId === projectId);
  }
);

export const sourceFieldsSelector = createSelector(
  (state) => state.sourceFields.entities,
  (state) => state.sourceFields.result,
  (state, projectId) => Number(projectId),
  (sourceFieldEntities, sourceFieldResults, projectId) => {
    const f = sourceFieldResults
      .reduce((acc, id) => {
        acc.push(sourceFieldEntities[id]);
        return acc;
      }, [])
      .filter((sf) => sf.projectId === projectId);

    return f;
  }
);
export const sourceFieldsMapSelector = createSelector(
  sourceFieldsSelector,
  (sf) =>
    sf.reduce((acc, val) => {
      acc[val.sourceFieldId] = val;
      return acc;
    }, {})
);

export const projectBatchesEntitiesSelector = createSelector(
  (state) => state.batches.entities,
  (state) => state.batches.result,
  (state, projectId) => projectId,
  (entities, result, projectId) => {
    const projectBatchesEntities = {};
    result.map((id) => {
      if (entities[id].projectId === projectId) {
        projectBatchesEntities[id] = entities[id];
      }
      return id;
    });
    return projectBatchesEntities;
  }
);

export const projectParentDeliverablesEntitiesSelector = createSelector(
  (state) => state.parentDeliverables.entities,
  (state) => state.parentDeliverables.result,
  projectBatchesEntitiesSelector,
  (entities, result, projectBatchEntities) => {
    const projectParentDeliverablesEntities = {};
    result.map((id) => {
      const batchId = String(entities[id].batchId);
      if (projectBatchEntities[batchId]) {
        projectParentDeliverablesEntities[id] = entities[id];
      }
      return id;
    });
    return projectParentDeliverablesEntities;
  }
);

export const projectDeliverablesEntitiesSelector = createSelector(
  (state) => state.deliverables.entities,
  (state) => state.deliverables.result,
  projectParentDeliverablesEntitiesSelector,
  (entities, result, projectParentDeliverableEntities) => {
    const projectDeliverablesEntities = {};
    result.map((id) => {
      const parentDeliverableId = String(entities[id].parentDeliverableId);
      if (projectParentDeliverableEntities[parentDeliverableId]) {
        projectDeliverablesEntities[id] = entities[id];
      }
      return id;
    });
    return projectDeliverablesEntities;
  }
);

export const projectDeliverablesSelector = createSelector(
  projectDeliverablesEntitiesSelector,
  (projectDeliverablesEntities) => {
    return Object.keys(projectDeliverablesEntities).map(
      (id) => projectDeliverablesEntities[id]
    );
  }
);

export const briefingFieldValuesByParentDeliverableSelector = createSelector(
  (state) => state.parentDeliverableBriefingFields.entities,
  (state) => state.parentDeliverableBriefingFields.result,
  (dbfEntities, dbfResult) => {
    /*
      turn all parentDeliverableBriefingFields into an object like:
        {
          parentDeliverableId: { briefingFieldId: briefingFieldValue }
        }
    */
    const briefingFieldValues = {};
    dbfResult.forEach((id) => {
      const { parentDeliverableId, briefingFieldId, fieldValue } =
        dbfEntities[id];
      if (!briefingFieldValues[parentDeliverableId])
        briefingFieldValues[parentDeliverableId] = {};
      briefingFieldValues[parentDeliverableId][briefingFieldId] = fieldValue;
    });
    return briefingFieldValues;
  }
);

export const sourceFieldValuesByParentDeliverableSelector = createSelector(
  (state) => state.parentDeliverableSourceFields.entities,
  (state) => state.parentDeliverableSourceFields.result,
  (dsfEntities, dsfResult) =>
    dsfResult.reduce((acc, id) => {
      const { parentDeliverableId, sourceFieldId, fieldValue, rawContent } =
        dsfEntities[id];
      if (!acc[parentDeliverableId]) acc[parentDeliverableId] = {};
      acc[parentDeliverableId][sourceFieldId] = { fieldValue, rawContent };
      return acc;
    }, {})
);

export const briefingFieldValuesSelector = createSelector(
  briefingFieldValuesByParentDeliverableSelector,
  (state, parentDeliverableEntities, projectBriefingFields) =>
    projectBriefingFields,
  (state, parentDeliverableEntities, projectBriefingFields) =>
    parentDeliverableEntities,
  (
    briefingFieldValuesByParentDeliverable,
    projectBriefingFields,
    parentDeliverableEntities
  ) => {
    /*
      map briefingFields into an object like:
      {
        parentDeliverableId: [array of projectBriefingFieldValues]
      }
    */

    const parentDeliverableBriefingFields = {};
    Object.keys(parentDeliverableEntities).forEach((parentDeliverableId) => {
      if (!parentDeliverableBriefingFields[parentDeliverableId])
        parentDeliverableBriefingFields[parentDeliverableId] = {};
      if (briefingFieldValuesByParentDeliverable[parentDeliverableId]) {
        parentDeliverableBriefingFields[parentDeliverableId] =
          projectBriefingFields.map(
            ({ briefingFieldId }) =>
              briefingFieldValuesByParentDeliverable[parentDeliverableId][
                briefingFieldId
              ]
          );
      } else {
        parentDeliverableBriefingFields[parentDeliverableId] = [];
      }
    });

    return parentDeliverableBriefingFields;
  }
);

export const assignmentGroupParentDeliverableEntitiesSelector = createSelector(
  (state, assignments) => assignments,
  (state) => state.deliverables.entities,
  (state) => state.parentDeliverables.entities,
  (state) => state.batches.entities,
  (state) => state.projects.entities,
  (assignments, deliverables, parentDeliverables, batches, projects) => {
    const parentDeliverableEntities = {};
    assignments.forEach((assignment) => {
      const deliverable = deliverables[assignment.deliverableId];
      if (!deliverable) return;
      parentDeliverableEntities[deliverable.parentDeliverableId] =
        parentDeliverables[deliverable.parentDeliverableId];
    });
    return parentDeliverableEntities;
  }
);

export const deliverableTaskFieldsWithContentSelector = createSelector(
  (deliverableContentEntities, projectTaskFields) => deliverableContentEntities,
  (deliverableContentEntities, projectTaskFields) => projectTaskFields,
  (deliverableContentEntities, projectTaskFields) => {
    const taskFields = {};
    projectTaskFields.forEach((tf) => {
      taskFields[tf.taskFieldId] = tf;
    });
    Object.keys(deliverableContentEntities).forEach((taskFieldContentId) => {
      const taskFieldContent = deliverableContentEntities[taskFieldContentId];
      const taskFieldId = taskFieldContent.taskFieldId;
      taskFields[taskFieldId] = {
        ...taskFields[taskFieldId],
        ...taskFieldContent,
      };
    });

    // maintain the order of projectTaskFields
    return projectTaskFields.map((tf) => taskFields[tf.taskFieldId]);
  }
);

export const projectLanguagesWithFullNameSelector = createSelector(
  (state) => state.languages.entities,
  projectLanguagesSelector,
  (languageEntities, projectLanguages) =>
    projectLanguages.map(({ languageCode }) => languageEntities[languageCode])
);

const PRODUCTION_STAGES = ["Production", "Amends"];
export const defaultAssigneesByStageAndLanguageSelector = createSelector(
  projectDefaultAssigneesSelector,
  projectLanguagesSelector,
  (state) => state.people.entities,
  (state, projectId) =>
    workflowStagesSelectorByTypes(
      state,
      state.projects.entities?.[projectId]?.workflowId,
      PRODUCTION_STAGES
    ),
  (defaultAssignees, projectLanguages, people, stages) => {
    const tree = {};

    // ensure even if no default assignees exist the stage + language tree exists
    stages.forEach(({ stageId }) => {
      if (!tree[stageId]) tree[stageId] = {};
      projectLanguages.forEach(({ languageCode }) => {
        if (!tree[stageId][languageCode]) tree[stageId][languageCode] = [];
      });
    });

    defaultAssignees.map((defaultAssignee) => {
      const { stageId, languageCode } = defaultAssignee;
      if (!tree[stageId]) tree[stageId] = {};
      if (!tree[stageId][languageCode]) tree[stageId][languageCode] = [];
      tree[stageId][languageCode].push(defaultAssignee);
      return defaultAssignee;
    });

    Object.keys(tree).forEach((stageId) => {
      Object.keys(tree[stageId]).forEach((languageCode) => {
        tree[stageId][languageCode].sort((a, b) => {
          const personA = people[a.personId];
          const personB = people[b.personId];

          // sort by firstName then by lastName (https://stackoverflow.com/a/24292023)
          return (
            personA.firstName.localeCompare(personB.firstName) ||
            personA.lastName.localeCompare(personB.lastName)
          );
        });
      });
    });

    return tree;
  }
);

export const totalWordsSelector = createSelector(
  (state) => state.editor,
  (state) => state.taskFields.entities,
  (editor, taskFieldEntities) => {
    let count = 0;

    Object.keys(editor).forEach((key) => {
      if (taskFieldEntities[key]) {
        count += editor[key].wordCount || 0;
      }
    });
    return count;
  }
);
/*
  {
    [orderFormId] : [ all projects for orderForm1 ],
    [orderFormId2] : [ all projects for orderForm2 ],
  }
*/
export const projectsTreeSelectorByOrderForm = createSelector(
  (state) => state.projects,
  (state) => state.hidden.entities,
  (state) => state.favourites.entities,
  (projects, hidden, favourites) => {
    const hiddenProjects = Object.values(hidden).reduce(
      (acc, { projectId }) => {
        if (projectId) acc.push(projectId);
        return acc;
      },
      []
    );
    const favouriteProjectIds = Object.values(favourites).reduce(
      (acc, { projectId }) => {
        if (projectId) acc.push(projectId);
        return acc;
      },
      []
    );

    const tree = {};
    projects.result.forEach((projectId) => {
      if (!hiddenProjects.includes(Number(projectId))) {
        const project = projects.entities[projectId];
        const { orderFormId } = project;
        if (!tree[orderFormId]) {
          tree[orderFormId] = [];
        }
        tree[orderFormId].push({
          isFavourite: favouriteProjectIds.includes(Number(projectId)),
          ...project,
        });
      }
    });
    return tree;
  }
);

/*
  {
    [accountId1] : [ all orderForms for account 1 ],
    [accountId2] : [ all orderForms for account 2 ],
  }
*/
export const orderFormsTreeSelectorByAccount = createSelector(
  (state) => state.orderForms,
  (state) => state.hidden.entities,
  (state) => state.favourites.entities,
  (orderForms, hidden, favourites) => {
    const hiddenOrderForms = Object.values(hidden).reduce(
      (acc, { orderFormId }) => {
        if (orderFormId) acc.push(orderFormId);
        return acc;
      },
      []
    );

    const favouriteOrderForms = Object.values(favourites).reduce(
      (acc, { orderFormId }) => {
        if (orderFormId) acc.push(orderFormId);
        return acc;
      },
      []
    );

    const tree = {};
    orderForms.result.forEach((orderFormId) => {
      if (!hiddenOrderForms.includes(Number(orderFormId))) {
        const orderForm = orderForms.entities[orderFormId];
        const { accountId } = orderForm;
        if (!tree[accountId]) {
          tree[accountId] = [];
        }
        tree[accountId].push({
          isFavourite: favouriteOrderForms.includes(Number(orderFormId)),
          ...orderForm,
        });
      }
    });
    return tree;
  }
);

export const doesTransitionExist = (transitions, from, to) => {
  const ts = Object.keys(transitions).filter(
    (k) =>
      transitions[k].toStageId === to && transitions[k].fromStageId === from
  );
  return ts.length > 0;
};

export const dashboardAssignmentSelector = createSelector(
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => assignments,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => deliverables,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => parentDeliverables,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => batches,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => projects,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => orderForms,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => accounts,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => stages,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions
  ) => transitions,
  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions,
    assignmentGroups
  ) => assignmentGroups,

  (
    assignments,
    deliverables,
    parentDeliverables,
    batches,
    projects,
    orderForms,
    accounts,
    stages,
    transitions,
    assignmentGroups
  ) => {
    const tree = {};

    const currentDate = new Date();
    assignments.forEach((assignment) => {
      const {
        assignmentId,
        rate,
        deadline,
        stageId,
        status,
        assignmentGroupId,
        reassigned,
      } = assignment;
      if (!reassigned) {
        const deliverableId = assignment.deliverableId;
        const deliverable = deliverables.find(
          (o) => o.deliverableId === deliverableId
        );
        if (deliverable === undefined) return;

        const currentStageId = deliverable.currentStageId;

        const parentDeliverableId = deliverable.parentDeliverableId;
        const parentDeliverable = parentDeliverables.find(
          (o) => o.parentDeliverableId === parentDeliverableId
        );
        if (parentDeliverable === undefined) return;

        const projectId = parentDeliverable.projectId;
        const project = projects.find((o) => o.projectId === projectId);
        if (project === undefined) return;
        const projectName = project.projectName;

        const batchId = parentDeliverable.batchId;
        const batch = batches.find((o) => o.batchId === batchId);
        if (batch === undefined) return;
        const batchName = batch.batchName;

        const orderFormId = project.orderFormId;
        const orderForm = orderForms.find((o) => o.orderFormId === orderFormId);
        if (orderForm === undefined) return;

        const accountId = orderForm.accountId;
        const account = accounts.find((o) => o.accountId === accountId);
        if (account === undefined) return;
        const accountName = account.accountName;

        const stage = stages.find((o) => o.stageId === stageId);
        if (stage === undefined) return;
        const stageName = stage.stageName.toLowerCase();

        if (!tree[assignmentGroupId]) tree[assignmentGroupId] = {};
        if (!tree[assignmentGroupId][projectName])
          tree[assignmentGroupId][projectName] = {};
        if (!tree[assignmentGroupId][projectName][batchName])
          tree[assignmentGroupId][projectName][batchName] = {};

        if (!tree[assignmentGroupId][projectName][batchName][rate]) {
          tree[assignmentGroupId][projectName][batchName][rate] = {};
        }

        if (!tree[assignmentGroupId][projectName][batchName][rate][deadline]) {
          tree[assignmentGroupId][projectName][batchName][rate][deadline] = {};
        }

        if (!tree[assignmentGroupId][projectName][batchName][rate][deadline]) {
          tree[assignmentGroupId][projectName][batchName][rate][deadline] = {};
        }

        if (
          !tree[assignmentGroupId][projectName][batchName][rate][deadline][
            stageId
          ]
        ) {
          tree[assignmentGroupId][projectName][batchName][rate][deadline][
            stageId
          ] = {};
        }

        if (
          !tree[assignmentGroupId][projectName][batchName][rate][deadline][
            stageId
          ][accountName]
        ) {
          tree[assignmentGroupId][projectName][batchName][rate][deadline][
            stageId
          ][accountName] = {
            assignmentGroupId,
            assignmentId,
            projectName,
            accountName,
            batchName,
            rate,
            deadline,
            stageId,
            stageName,
            overdue: new Date(deadline) < currentDate,
            count: 0,
            inTraining:
              assignmentGroups &&
              assignmentGroups[assignmentGroupId] &&
              assignmentGroups[assignmentGroupId].inTraining,
            assignments: {
              new: 0,
              current: 0,
              upcoming: 0,
              submitted: 0,
              approved: 0,
            },
          };
        }

        const targetAssignments =
          tree[assignmentGroupId][projectName][batchName][rate][deadline][
            stageId
          ][accountName].assignments;

        tree[assignmentGroupId][projectName][batchName][rate][deadline][
          stageId
        ][accountName].count += 1;

        /* eslint-disable */
        if (status === "Not Actioned") {
          targetAssignments.new += 1;
        } else if (status === "Accepted" || status === "Amends requested") {
          if (stageId === currentStageId) {
            targetAssignments.current += 1;
          } else {
            targetAssignments.upcoming += 1;
          }
        } else if (status === "Submitted") {
          if (doesTransitionExist(transitions, stageId, currentStageId)) {
            targetAssignments.submitted += 1;
          }
        } else if (status === "Approved") {
          targetAssignments.approved += 1;
        }
      }
    });

    let flattenedTree = [];
    Object.keys(tree).forEach((assignmentGroupId) => {
      Object.keys(tree[assignmentGroupId]).forEach((projectName) => {
        Object.keys(tree[assignmentGroupId][projectName]).forEach(
          (batchName) => {
            Object.keys(
              tree[assignmentGroupId][projectName][batchName]
            ).forEach((rate) => {
              Object.keys(
                tree[assignmentGroupId][projectName][batchName][rate]
              ).forEach((deadline) => {
                Object.keys(
                  tree[assignmentGroupId][projectName][batchName][rate][
                    deadline
                  ]
                ).forEach((stageId) => {
                  Object.keys(
                    tree[assignmentGroupId][projectName][batchName][rate][
                      deadline
                    ][stageId]
                  ).forEach((accountName) => {
                    flattenedTree.push(
                      tree[assignmentGroupId][projectName][batchName][rate][
                        deadline
                      ][stageId][accountName]
                    );
                  });
                });
              });
            });
          }
        );
      });
    });
    /* eslint-enable */

    return flattenedTree;
  }
);

export const primaryStageSelector = createSelector(
  workflowStagesSelector,
  (workflowStages) => {
    const primaryStage = workflowStages.find((s) => s.isPrimary);
    return primaryStage ? primaryStage.stageId : null;
  }
);

export function UTCToLocalTimeString(dateString) {
  const date = new Date(dateString);
  const timeOffsetInHours = new Date().getTimezoneOffset() / 60;
  date.setHours(date.getHours() - timeOffsetInHours);
  const now = new Date();
  const difference = (now.getTime() - date.getTime()) / 1000;

  const oneMinute = 60;
  const oneHour = 3600;
  const oneDay = 86400;
  const oneMonth = 2592000;
  const oneYear = 31104000;

  if (difference < oneMinute) {
    return "a few seconds ago";
  } else if (difference < oneHour) {
    const time = parseInt(difference / oneMinute);
    return `${time} minute${time === 1 ? "" : "s"} ago`;
  } else if (difference < oneDay) {
    const time = parseInt(difference / oneHour);
    return `${time} hour${time === 1 ? "" : "s"} ago`;
  } else if (difference < oneMonth) {
    const time = parseInt(difference / oneDay);
    return `${time} day${time === 1 ? "" : "s"} ago`;
  } else if (difference < oneYear) {
    const time = parseInt(difference / oneMonth);
    return `${time} month${time === 1 ? "" : "s"} ago`;
  } else {
    const time = parseInt(difference / oneYear);
    return `${time} year${time === 1 ? "" : "s"} ago`;
  }
}

export const commenterNameSelector = createSelector(
  (comments, people) => comments,
  (comments, people) => people.result,
  (comments, people) => people.entities,
  (comments, result, entities) => {
    return comments.map((comment) => {
      // Retrieve the person from the state based on the comment.personId
      const person =
        entities[
          result.filter((r) => entities[r].personId === comment.personId)[0]
        ];
      if (typeof person === "undefined") return { ...comment };

      const commenterName = `${person.firstName} ${person.lastName}`;

      const localDate = UTCToLocalTimeString(comment.createDate);

      return { ...comment, commenterName, date: localDate };
    });
  }
);

export const accountsFromPersonAccountsSelector = createSelector(
  (personId, state, whichField) => personId,
  (personId, state, whichField) => state.personAccounts,
  (personId, state, whichField) => state.accounts.entities,
  (personId, state, whichField) => state.accounts.result,
  (personId, state, whichField) => whichField,
  (personId, personAccounts, accountEntities, accountResult, whichField) => {
    const accounts = [];

    personAccounts.map((pa) => {
      if (pa.personId === personId) {
        // if the personAccount matches the person
        accountResult.map((ar) => {
          // loop over all accounts
          const account = accountEntities[ar];
          if (account.accountId === pa.accountId) {
            // if the account id's match, then add it to be returned
            accounts.push(account[whichField]);
          }
          return ar;
        });
      }
      return pa;
    });

    return accounts;
  }
);

export const personNativeLanguagesByPersonId = createSelector(
  (state) => state.personNativeLanguages,
  (state, personId) => personId,
  (personNativeLanguages, personId) => {
    return personNativeLanguages.filter((i) => i.personId === personId);
  }
);

export const personRolesSelector = createSelector(
  (state) => state.personRoles,
  (state, personId) => personId,
  (personRoles, personId) =>
    personRoles.filter((pr) => pr.personId === personId)
);

export const projectTeamsCandidateSelector = createSelector(
  (state) => state.people.entities,
  (state) => state.people.result,
  (state, inclusionType) => inclusionType,
  (entities, result, inclusionType) => {
    const filteredPeople = [];

    result.forEach((id) => {
      const person = entities[id];
      const eligibleAsMember = inclusionType
        ? person.personType === inclusionType
        : person.personType !== "Client";
      if (eligibleAsMember) filteredPeople.push(entities[id]);
    });

    return filteredPeople;
  }
);

export const transformToDropdownFormat = (list, key, labelKeys) => {
  return list.map((l) => ({
    value: l[key],
    label: labelKeys.map((labelKey) => l[labelKey]).join(" "),
  }));
};

// priorStages based on assignments on the deliverable id
const priorAssignmentStagesSelector = createSelector(
  (state) => state.assignments.result,
  (state) => state.assignments.entities,
  (state) => state.stages.entities,
  (state, stageId, deliverableId) => stageId,
  (state, stageId, deliverableId) => deliverableId,
  (
    assignmentIds,
    assignmentEntities,
    stageEntities,
    stageId,
    deliverableId
  ) => {
    const stagesSorted = assignmentIds
      .reduce((acc, id) => {
        if (assignmentEntities[id].deliverableId === deliverableId) {
          return acc.concat(stageEntities[assignmentEntities[id].stageId]);
        }
        return acc;
      }, [])
      .sort((a, b) => a.stagePosition - b.stagePosition);

    const currentStageIndex = stagesSorted.findIndex(
      (stage) => stage.stageId === stageId
    );
    const priorStagePosition =
      stagesSorted[currentStageIndex - 1] &&
      stagesSorted[currentStageIndex - 1].stagePosition;

    // in case we have multiple prior stages
    return stagesSorted.filter(
      (stage) => stage.stagePosition === priorStagePosition
    );
  }
);

// next stages based on whether in training is true
const nextStagesSelector = createSelector(
  (state, stageId, deliverableId) => state.transitions.result,
  (state, stageId, deliverableId) => state.transitions.entities,
  (state, stageId, deliverableId) => state.stages.entities,
  (state, stageId, deliverableId) => stageId,
  (state, stageId, deliverableId) => {
    if (!stageId || !deliverableId) return false;

    // If we are in client stages inTraining should be false
    const stage = state.stages.entities[stageId];
    if (stage.stageType === "Client") return false;

    let inTraining;
    for (let i = 0; i < state.assignments.result.length; i++) {
      const id = state.assignments.result[i];
      const assignment = state.assignments.entities[id];
      if (
        assignment.stageId === stageId &&
        assignment.deliverableId === deliverableId &&
        !assignment.reassigned
      ) {
        inTraining =
          state.assignmentGroups.entities[assignment.assignmentGroupId] &&
          state.assignmentGroups.entities[assignment.assignmentGroupId]
            .inTraining;
        break;
      }
    }
    return inTraining;
  },
  (
    transitionIds,
    transitionEntries,
    stageEntities,
    fromStageId,
    inTraining
  ) => {
    const nextStages = [];
    // when inTraining is undefined then it implies we have not retrieved assignmentGroups details yet.
    // In that case we return no next stages (better to return an empty array then an array of the wrong next stages)
    if (typeof inTraining === "undefined") {
      return nextStages;
    }

    transitionIds.forEach((id) => {
      const transition = transitionEntries[id];
      if (
        transition.fromStageId === fromStageId &&
        ((transition.forwardTransition &&
          inTraining &&
          stageEntities[transition.toStageId].stageType === "Training") ||
          (transition.forwardTransition &&
            !inTraining &&
            stageEntities[transition.toStageId].stageType !== "Training"))
      ) {
        nextStages.push(stageEntities[transition.toStageId]);
      }
    });

    return nextStages;
  }
);

export const priorAndNextStagesSelector = createSelector(
  priorAssignmentStagesSelector,
  nextStagesSelector,
  (priorAssignmentStages, nextStages) => {
    return priorAssignmentStages.concat(nextStages);
  }
);

export const recallTransitionsSelector = createSelector(
  transitionsSelector,
  (state) => state,
  (state, stageId) => stageId,
  (state, stageId, deliverableId) =>
    transitionLogSelector(state, deliverableId),
  (state, stageId, deliverableId) => Number(deliverableId),
  (state, stageId, deliverableId, projectWorkflowId) => projectWorkflowId,
  (
    transitions,
    state,
    stageId,
    transitionLogs,
    deliverableId,
    projectWorkflowId
  ) => {
    const transitionIds = new Set();
    const [lastTransitionLog, secondLastTransitionLog] = [...transitionLogs]
      .filter(({ transitionId, deliverableId: logDeliverableId }) => {
        const transition = state.transitions.entities[transitionId];
        const { transitionName } = transition;
        const include =
          logDeliverableId === deliverableId &&
          transitionName !== "Recall" &&
          !transitionIds.has(transitionId);
        transitionIds.add(transitionId);
        return include;
      })
      .sort((a, b) => new Date(b.createDate) - new Date(a.createDate));

    const lastTransition = lastTransitionLog
      ? state.transitions.entities[lastTransitionLog.transitionId]
      : null;
    const secondLastTransition = secondLastTransitionLog
      ? state.transitions.entities[secondLastTransitionLog.transitionId]
      : null;

    return transitions.filter(
      ({ workflowId, fromStageId, toStageId, transitionName }) => {
        return (
          workflowId === projectWorkflowId &&
          fromStageId === stageId &&
          lastTransition &&
          secondLastTransition &&
          transitionName === "Recall" &&
          lastTransition.fromStageId === toStageId
        );
      }
    );
  }
);

/**
 * Returns a list of transitions to go to the previous/next states
 *
 * @param {Object} state
 * @param {number} stageId
 * @param {number} deliverableId
 * @param {number} workflowId
 * @param {string} loggedInUserType deprecated
 * @param {boolean} includeRecall
 *
 * @returns {object[]} array of transitions
 */
export const priorAndNextTransitionsSelector = createSelector(
  priorAssignmentStagesSelector,
  nextStagesSelector,
  recallTransitionsSelector,
  (state) => state.transitions.result,
  (state) => state.transitions.entities,
  (state, stageId) => stageId,
  (state, stageId, deliverableId, workflowId) => workflowId,
  (state, stageId, deliverableId, workflowId, loggedInUserType) =>
    loggedInUserType,
  (
    state,
    stageId,
    deliverableId,
    workflowId,
    loggedInUserType,
    includeRecall
  ) => includeRecall,
  (
    priorAssignmentStages,
    nextStages,
    recallTransitions,
    transitionIds,
    transitionEntities,
    stageId,
    workflowId,
    loggedInUserType,
    includeRecall = false
  ) => {
    let transitions = transitionIds.reduce((acc, id) => {
      const transition = transitionEntities[id];
      const {
        workflowId: transitionWorkflowId,
        fromStageId,
        toStageId,
        forwardTransition,
        transitionName,
      } = transition;

      if (
        (workflowId && transitionWorkflowId !== workflowId) ||
        transitionName.includes("Recall")
      )
        return acc;

      if (
        fromStageId === stageId &&
        /*  backward transitions, no priorAssignmentStages means we are at
              client / amends stages who dont have immediate prior assignments so
              we send any backward transitions for clients / amends stages if they exist */
        ((forwardTransition &&
          nextStages.find((s) => s.stageId === toStageId)) ||
          (!forwardTransition && !priorAssignmentStages.length) ||
          (!forwardTransition &&
            priorAssignmentStages.length &&
            priorAssignmentStages.find((s) => s.stageId === toStageId)))
      ) {
        return acc.concat(transition);
      }

      return acc;
    }, []);

    if (!includeRecall) return transitions;

    return [...transitions, ...recallTransitions];
  }
);

export const wasModifiedByDeliverableId = createSelector(
  (state) => state.deliverables.entities,
  (state) => state.taskFieldContent.entities,
  (state) => state.people.entities,
  (deliverables, taskFieldContent, people) => {
    const modifiedDeliverables = {};
    Object.keys(deliverables).forEach((deliverableId) => {
      const deliverableTfc = Object.values(taskFieldContent).filter(
        (tfc) => Number(tfc.deliverableId) === Number(deliverableId)
      );
      const latestNonClientContent = Object.values(deliverableTfc).reduce(
        (acc, cur) => {
          const { taskFieldId, personId } = cur;
          const { personType } = people[personId] || {};
          const isClient = personType === "Client";
          if (
            !acc[taskFieldId] ||
            (cur.createDate > acc[taskFieldId].createDate && !isClient)
          ) {
            acc[taskFieldId] = cur;
          }
          return acc;
        },
        {}
      );

      const latestContent = Object.values(deliverableTfc).reduce((acc, cur) => {
        const { taskFieldId } = cur;

        if (!acc[taskFieldId] || cur.createDate > acc[taskFieldId].createDate) {
          acc[taskFieldId] = cur;
        }
        return acc;
      }, {});

      const wasModified = Object.values(latestContent).some(
        ({ content, taskFieldId }) => {
          const nonClientTfc =
            Object.values(latestNonClientContent).find(
              (clientTfc) => clientTfc.taskFieldId === taskFieldId
            ) || {};
          return nonClientTfc.content !== content;
        }
      );
      modifiedDeliverables[deliverableId] = wasModified;
    });

    return modifiedDeliverables;
  }
);

export const batchDeliverablesSelector = createSelector(
  wasModifiedByDeliverableId,
  briefingFieldValuesSelector,
  getLoggedInUser,
  (state) => state.deliverables.entities,
  (state) => state.deliverables.result,
  (state, batchParentDeliverableEntities, projectBriefingFields, stage) =>
    clientStageSelector(state, stage.stageId),
  (state, batchParentDeliverableEntities) => batchParentDeliverableEntities,
  (
    state,
    batchParentDeliverableEntities,
    projectBriefingFields,
    stage,
    selectedLanguage
  ) => selectedLanguage,
  (
    modifiedDeliverables,
    briefingFieldValues,
    user,
    deliverableEntities,
    deliverableResult,
    stage,
    batchParentDeliverableEntities,
    selectedLanguage
  ) => {
    // If no selectedLanguage then return nothing
    if (selectedLanguage === null) return [];

    const isInHouse = user.personType === PersonType.Admin;

    // In house users can action everything
    // If a stage is a client stage then it should also always be actionable
    // Final stages are never actionable in bulk
    const actionable =
      !stage.isFinal || (isInHouse && stage.stageType === "Client");

    const selectable =
      actionable || (!stage.isFinal && (isInHouse || stage.actionable));

    const batchDeliverablesAtLanguage = [];

    deliverableResult.forEach((deliverableId) => {
      const { currentStageId, languageCode, parentDeliverableId } =
        deliverableEntities[deliverableId];

      if (
        currentStageId === stage.stageId &&
        languageCode === selectedLanguage &&
        batchParentDeliverableEntities[parentDeliverableId]
      ) {
        batchDeliverablesAtLanguage.push({
          actionable,
          briefingFieldValues: briefingFieldValues[parentDeliverableId],
          id: Number(deliverableId),
          selectable,
          viewable: stage.viewable,
          wasModified: modifiedDeliverables[deliverableId],
        });
      }
    });

    return batchDeliverablesAtLanguage;
  }
);

/**
 * This function also adds on the deliverableId (so that only admins see it and not clients)
 *
 * @returns {Object[]} array of batch deliverables
 */
export const adminBatchDeliverablesSelector = createSelector(
  batchDeliverablesSelector,
  (deliverables) => deliverables.map((d) => ({ ...d, deliverableId: d.id }))
);

export const clientStageSelector = createSelector(
  (state) => state.stages.entities,
  (state, stageId) => stageId,
  (stages, stageId) => {
    const stage = stages[stageId];
    const actionable = stage.stageType === "Client" && !stage.isFinal;
    const viewable =
      stage.stageType === "Client" || stage.stageName === "Content in amends";
    return { ...stage, actionable, viewable };
  }
);

export const sameLanguageBatchStageDeliverables = createSelector(
  (
    state,
    stageId,
    batchParentDeliverableEntities,
    currentDeliverableLanguage
  ) => stageId,
  (
    state,
    stageId,
    batchParentDeliverableEntities,
    currentDeliverableLanguage
  ) => batchParentDeliverableEntities,
  (
    state,
    stageId,
    batchParentDeliverableEntities,
    currentDeliverableLanguage
  ) => currentDeliverableLanguage,
  (state) => state.deliverables.entities,
  (state) => state.deliverables.result,
  (
    stageId,
    batchParentDeliverableEntities,
    currentDeliverableLanguage,
    deliverableEntities,
    deliverableResult
  ) => {
    const deliverables = [];
    deliverableResult.forEach((id) => {
      const deliverable = deliverableEntities[id];
      const { parentDeliverableId, languageCode, currentStageId } = deliverable;
      if (
        batchParentDeliverableEntities[parentDeliverableId] &&
        languageCode === currentDeliverableLanguage &&
        stageId === currentStageId
      ) {
        deliverables.push(deliverable);
      }
    });
    return deliverables;
  }
);

export const currentLanguageSelector = createSelector(
  projectLanguagesSelector,
  (projectLanguages) => {
    const selectedLanguage = projectLanguages.find((pl) => pl.selected);
    return selectedLanguage ? selectedLanguage.languageCode : "all";
  }
);

export const languageNamesFromLanguages = createSelector(
  (languages) => languages,
  (languages) =>
    languages.reduce((acc, l) => {
      acc[l.languageCode] = l.languageName;
      return acc;
    }, {})
);

// Used to convert arrays to object format, using a specified key
export const entityBuilder = (fields, key) =>
  fields.reduce((acc, val) => {
    acc[val[key]] = val;
    return acc;
  }, {});

// Not sure if adding id and key is going to break anything...
// doesn't seem like it 😬
export const itemToValueAndLabel = (item) => ({
  id: item,
  key: item,
  value: item,
  label: item,
});
export const listToValueAndLabel = (list) => list.map(itemToValueAndLabel);
export const listToValueAndLabelMap = (list) =>
  list.map((item) => ({ value: item.value, label: item.label }));

export const clientDeadlineStagesSelector = createSelector(
  workflowStagesSelector,
  (workflowStages) =>
    workflowStages.filter(
      ({ isFinal, stageType }) => !isFinal && stageType === "Client"
    )
);

export const filterForCreated = (arr, updateItems, idKey) => {
  return arr.filter(
    (a) => !updateItems.some((item) => item[idKey] === a[idKey])
  );
};

export const rateBandItemsSelector = createSelector(
  (state) => state.rateBandItems,
  (state, rateBands) => rateBands,
  (state, rateBands, projectId) => projectId,
  (rateBandItems, rateBands, projectId) => {
    // get all the rateBandIds for the current project
    const rateBandIds = rateBands
      .filter(({ projectId: rbProjectId }) => rbProjectId === projectId)
      .map(({ rateBandId }) => rateBandId);

    // get all the rateBandItem entities which map to our rateBandIds
    return rateBandItems
      .filter((rateBandItem) => rateBandIds.includes(rateBandItem.rateBandId))
      .reduce((acc, { rateBandId, stageId, languageCode, rate }) => {
        if (!acc[rateBandId]) acc[rateBandId] = {};
        if (!acc[rateBandId][stageId]) acc[rateBandId][stageId] = {};
        if (!acc[rateBandId][stageId][languageCode])
          acc[rateBandId][stageId][languageCode] = rate;

        return acc;
      }, {});
  }
);

export const getNextParentDeliverableIdSelector = createSelector(
  (state) => state.parentDeliverables.entities,
  (state, currentParentDeliverableId) => currentParentDeliverableId,
  (parentDeliverables, currentParentDeliverableId) => {
    const currentParentDeliverable =
      parentDeliverables[currentParentDeliverableId];

    if (!currentParentDeliverable) return null;

    const { batchId } = currentParentDeliverable;

    const batchParentDeliverables = Object.values(parentDeliverables)
      .filter((pd) => pd.batchId === batchId)
      .sort((a, b) => a.parentDeliverableId - b.parentDeliverableId);

    const currentIndex = batchParentDeliverables.findIndex(
      (d) => d.parentDeliverableId === currentParentDeliverableId
    );

    if (
      currentIndex !== -1 &&
      currentIndex < batchParentDeliverables.length - 1
    ) {
      return batchParentDeliverables[currentIndex + 1].parentDeliverableId;
    }

    // If it's the last deliverable or not found, return null
    return null;
  }
);

const createWorkflowTypeSelector = (type) => {
  return createSelector(
    projectById,
    (state) => state.workflows.entities,
    ({ workflowId }, workflows) =>
      workflowId && workflows[workflowId].workflowType === type
  );
};

/**
 * @param {Object} state
 * @param {number} projectId
 * @returns {bool|undefined}  true if project is a localisation project,
 *  false if not, or undefined if unknown
 */
export const isProjectLocalisation = createWorkflowTypeSelector("Localisation");

/**
 * @param {Object} state
 * @param {number} projectId
 * @returns {bool|undefined}  true if project is a creation project,
 *  false if not, or undefined if unknown
 */
export const isProjectCreation = createWorkflowTypeSelector("Creation");
